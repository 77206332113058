import React from 'react';
import Layout from '../layout/Layout';
import Seo from '../layout/Seo';

const politics = ({pageContext}) => {
    const {locale} = pageContext

    function Child({locale}){
        return(<>
         <Seo title="Error 404" description="Error 404" pathname="https:localhost"/>
  <section className="flex mt-9">
      <div className="flex-auto sm:flex-1"></div>
      <div className="flex sm:flex-1 justify-end sm:justify-start">
          <div className="flex flex-col bg-primary p-2 md:p-5 rounded-br-xl md:rounded-br-medium  text-white w-7/12 md:w-8/12 xl:w-6/12 sm:ml-2 mr-4">
              <span className="text-2xl leading-5 md:leading-7 lg:leading-normal md:text-3xl xl:text-4xl font-bold">
              OBRA YA
              </span>
              <span className="hidden md:block md:text-sm xl:text-xl font-bold mt-1">
              ¡Gracias por utilizar OBRA YA!
              </span>
          </div>
      </div>
  </section>

  <section className="p-5 md:p-2 font-serif text-secondary">
      <div className="flex flex-col md:flex-row self-center lg:max-w-3xl rounded-lg bg-white m-auto  mt-3 md:mt-6 text-center p-3">
          <div className="w-full mt-3 p-1 md:p-8 text-justify">
              <div className="flex flex-col">

                  <span className="text-primary uppercase font-bold text-xl font-serif">
                    POLÍTICA DE PRIVACIDAD Y SEGURIDAD
                  </span>
                  <span className="text-secondary font-light text-sm font-serif my-5">
                  La información dada acerca de la Política de Privacidad demuestra el compromiso de OBRA YA de garantizar y preservar las relaciones comerciales seguras mediante la protección de datos personales y respetando el derecho a la privacidad de cada uno de los usuarios (Clientes y Profesionales) dentro de los servicios prestados por OBRA YA.
                      <br/><br />
                      Todas las informaciones correspondientes a una persona física identificable, se consideran Datos Personales, pudiendo ser nombre, apellido, dirección, email, número de teléfono, entre otros.
                      <br />
                    <br />
                      <span className="text-primary uppercase font-bold text-xl font-serif">
                      TRATAMIENTO Y FINALIDAD DE LOS DATOS RECAUDADOS
                        </span>
                        <br />
                    <br />
                    Los datos facilitados por los usuarios al momento de visitar cualquiera de los servicios de OBRA YA, bien sea esporádicos o de registro, serán tratados de acuerdo a las normas de protección de datos y sólo serán recogidos, tratados y utilizados con fines lícitos, legítimos e informados. Las finalidades del uso de datos personales se detallan a continuación, siendo OBRA YA o terceros responsable del tratamiento:


                      <br/>
                      <br/>
                      Gestionar todos los formularios de registro para poder convertirse en Usuario de OBRA YA, acceder a los servicios, tener la posibilidad de contactar y responder cuestiones planteadas y recibir información comercial sobre nuevos productos, novedades, noticias y demás comunicaciones de la empresa.


                      <br />
                      <br />
                      La recopilación de datos también se puede dar con la finalidad de responder consultas mediante el envío de formularios de contacto, emails o chat de atención al cliente. Dichos datos obtenidos por soporte de OBRA YA son para responder a los propios usuarios, resolver problemas técnicos y remitir información comercial.

                    <br />
                    <br />
                    Se informará a todos los usuarios el carácter obligatorio o no de la recogida de determinados datos personales. El no cumplimiento de dichos datos podrán impedir a OBRA YA a prestar todos los servicios vinculados a los datos requeridos. OBRA YA queda libre de toda responsabilidad por la no presentación o prestación incompleta de estos servicios.

                    <br />
                    <br />
                    Corresponde al Usuario la obligación de facilitar los datos de manera verídica, mantenerlos actualizados y velar por que los datos no tengan errores, por lo que OBRA YA se reserva el derecho de excluir de los servicios y proceder a la cancelación del servicio, suscripciones, certificaciones de cualquier producto OBRA YA a todos los Usuarios que hayan incluído datos falsos, sin perjuicio de las demás acciones que procedan en Derecho
                    <br />
                    <br />
                    La información facilitada por el usuario permitirá a OBRA YA:
                    <br />
                    <br />
                    Utilizar toda la información con carácter comercial para la personalización de los servicios y realizar tratamientos estadísticos para realizar actividades de investigación y comercialización de los servicios y productos de OBRA YA así como elaboración de perfiles e informes estadísticos relacionados con la actividad del usuario durante la navegación en el sitio web y/o la aplicación o su participación en acciones comerciales, publicitarias o promocionales. 

                    <br />
                    <br />
                    Desarrollar y mostrar publicidad adaptada a sus preferencias personales. Lo que podrá suponer el envío de comunicaciones comerciales, publicitarias o promocionales de productos y servicios de OBRA YA que estuvieran relacionados con los servicios solicitados, así como de actualizaciones de los contenidos del sitio web y/o la aplicación. 
                    <br />
                    <br />
                    Verificar la inexistencia de actuaciones fraudulentas o contrarias a las condiciones de uso del sitio web y la aplicación, así como las Políticas de Privacidad y Cookies. 
                    <br />
                    <br />
                    Identificar y verificar que el usuario cumple con los requisitos necesarios para poder utilizar el sitio web y/o la aplicación y realizar sus acciones de forma lícita. 
                    <br />
                    <br />
                    Eliminar justificadamente a cualquier usuario que incumpla las condiciones de uso del sitio web y/o la aplicación.
                    <br />
                    <br />
                    <span className="text-primary uppercase font-bold text-xl font-serif">
                    III. LEGITIMACIÓN DE TRATAMIENTO DE DATOS

                        </span>
                        <br />
                    <br />
                    La base legal para el tratamiento de los datos es la respuesta a las consultas planteadas o llevar a cabo el registro de usuario a través de la página web o la Plataforma Móvil.

                    <br />
                    <br />
                    <span className="text-primary uppercase font-bold text-xl font-serif">
                    IV. PLAZO PARA LA CONSERVACIÓN DE DATOS


                        </span>
                        Los datos serán conservados el tiempo necesario, para dar respuesta a las consultas o para la gestión integral del registro como bien como Cliente bien como Profesional hasta que cada titular de los datos manifiesta su voluntad de que cese el tratamiento de sus datos o para cumplir con el plazo legal previsto para el cumplimiento de determinadas obligaciones fiscales.

                    <br />
                    <br />
                    <span className="text-primary uppercase font-bold text-xl font-serif">
                    V. COMUNICACIÓN DE DATOS PERSONALES A TERCEROS

                        </span>
                        <br />
                    <br />
                        Al realizar una consulta a través de formularios OBRA YA, los datos personales del Usuario NO serán cedidos ni comunicados a ningún tercero.

                   
                    <br />
                    <br />
                    En cambio, para la correcta gestión de la finalidad de OBRA YA, los datos de los Clientes serán compartidos con los Profesionales para que éstos puedan proceder a presupuestar y cotizar los servicios solicitados. Fuera de este supuesto no se comunicarán los datos a ningún tercero. En el caso de que fuera necesario comunicar su información a algún otro tercero se solicitará el consentimiento del Usuario involucrado.
                    <br />
                    <br />
                    <span className="text-primary uppercase font-bold text-xl font-serif">
                    VI. SEGURIDAD DE DATOS PERSONALES

                        </span>
                        <br />
                    <br />
                    Los datos son almacenados en los sistemas de información OBRA YA, donde se adoptan e implementan medidas de seguridad, técnicas y organizativas, para prevenir cualquier pérdida o uso no autorizado por terceros. En los sitios web OBRA YA se utilizan protocolos de cifrado de datos Https.
<br /><br />
<span className="text-primary uppercase font-bold text-xl font-serif">
VII. UTILIZACIÓN DE COOKIES


                        </span>
                    <br /><br />
                    Al ingresar a cualquier producto de OBRA YA (sitios webs, APPs, otros) no queda registrado de forma automática ningún dato de carácter personal que identifique a un Usuario. Sin embargo, durante la navegación por los diversos sitios webs y apps se utilizan “cookies”, pequeños ficheros de datos que se generan en el ordenador del internauta y que permiten obtener la siguiente información analítica:
      
                    <br /><br />
                    a) La fecha y hora de acceso a la Web, permitiendo saber las horas de más afluencia, y hacer los ajustes precisos para evitar problemas de saturación.
 <br /><br />
 b) El número de visitantes diarios de cada sección, permitiendo conocer las áreas de más éxito y aumentar y mejorar su contenido, con el fin de que los usuarios obtengan un resultado más satisfactorio y mejorar el diseño de los contenidos.
<br /><br />
c) La fecha y hora de la última vez que el usuario visitó los productos para realizar estudios analíticos y estadísticos sobre el uso.<br /><br />
d) Elementos de seguridad que intervienen en el control de acceso a las áreas restringidas.
<br /><br />
Las cookies son identificadores alfanuméricos que son transferidos al disco duro del ordenador del usuario a través de los navegadores. Esto permite a los sistemas de OBRA YA reconocer el navegador del Usuario e identificar cómo y cuándo las páginas webs de nuestros productos son visitadas y por cuántos Usuarios. Las cookies de OBRA YA no recopilan información personal y no se combina información general obtenida a través de cookies con otra información personal.<br /><br />
El usuario podrá, dependiendo del navegador que utilice, desactivar la función de cookies. 

<br /><br />
Cualquier usuario debe leer y aceptar nuestra Política de Cookies antes de hacer uso de cualquier producto OBRA YA. 
<br /><br />
<span className="text-primary uppercase font-bold text-xl font-serif">
VIII. DERECHO DEL USUARIO

                        </span><br /><br />
                        Todos los usuarios pueden hacer uso de cualquiera de los derechos otorgados por la normativa de protección de datos, como el derecho de acceso, limitación del tratamiento, supresión, portabilidad, etc. mediante escrito dirigido a OBRA YA o enviando un correo electrónico a servicio@obraya.com
<br /><br />
En el caso de que cualquier usuario no se encuentre satisfecho con la solución aportada por la empresa, podrá acudir y tramitar la pertinente reclamación ante la Agencia Colombiana de Protección de Datos.<br /><br />
<span className="text-primary uppercase font-bold text-xl font-serif">
IX. REVOCACIÓN DE CONSENTIMIENTO

                        </span><br /><br />
                        Cualquier Usuario puede oponerse al uso de su información para fines publicitarios, investigaciones de mercado o desarrollo de encuestas de satisfacción, así como revocar su consentimiento en cualquier momento (sin efecto retroactivo). Para ello, deberá enviar un correo electrónico a la dirección servicio@obraya.com.

                        <br /><br />
                        <span className="text-primary uppercase font-bold text-xl font-serif">
                        X. CAMBIOS EN POLÍTICAS DE PRIVACIDAD

                        </span><br /><br />
                        Las Políticas de Privacidad de OBRA YA podrán sufrir actualizaciones, cambios y demás modificaciones acordes a las necesidades legales de la empresa. Se recomienda al usuario visitar y acceder a las Políticas de Privacidad de forma periódica con el fin de conocer los últimos cambios que pudieran ser incorporados.<br /><br />
                        La lectura y comprensión de las Políticas de Privacidad de OBRA YA son obligatorias para cualquier persona que ingrese o use los productos OBRA YA. En caso de tener dudas o inquietudes el usuario podrá contactar a OBRA YA a través del siguiente email: servicio@obraya.com
                  </span>
              </div>
          </div>
      </div>
  </section>
        </>)
    }
  return <Layout locale={locale}
  background="bg-image-about">
  {props => <Child {...props} />}
</Layout>;
};
export default politics